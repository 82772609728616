import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import { useLoadData } from '../utils';
export function AsyncAutocomplete<K, T extends { name: string, id: number }>(p: {
    label: string,
    suggestions: () => Promise<{ items?: T[] }>,
    searchWord: K,
} & ({

    defaultValue?: T[],
    multiple: true,
    handleAsyncChange: (key: K, value: number[], items: T[]) => void,
    maxSelection?: number;
} | {

    defaultValue?: T,
    multiple?: false,
    handleAsyncChange: (key: K, value: number, items: T) => void,
})) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<T[]>([]);
    const loading = open && options.length === 0;
    useLoadData(async () => {
        const response = await p.suggestions();
        setOptions(response.items || []);
    });
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let active = true;
        if (!loading) {
            return undefined;
        }
        return () => {
            active = false;
        };
    }, [loading]);
    if (options.length === 0) {
        return <div></div>;
    }
    return (
        <Autocomplete<T, any, any, any>
            multiple={p.multiple || false}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            disableClearable
            getOptionLabel={option => option && (option as T).name}
            defaultValue={options.length && p.defaultValue && p.multiple ?
                (p.defaultValue
                    && Array.isArray(p.defaultValue)
                    && p.defaultValue.length > 0
                    ? p.defaultValue.map(e => options[options.map(o => o.id).indexOf(e.id)]) : [])
                : p.multiple && !p.defaultValue ? [] : p.defaultValue}
            onChange={(event, value) => {
                if (Array.isArray(value)) {
                    if (p.multiple) {
                        if (p.maxSelection) {
                            if (value.length <= p.maxSelection) {
                                p.handleAsyncChange(
                                    p.searchWord,
                                    (value as T[]).map((e) => e.id),
                                    value as T[],
                                );
                            } else {
                                window.alert(
                                    `Can't add more than ${p.maxSelection} related routines`
                                );
                            }
                        } else
                            if (!p.maxSelection) {
                                p.handleAsyncChange(
                                    p.searchWord,
                                    (value as T[]).map((e) => e.id),
                                    value as T[],
                                );
                            }
                    }
                } else {
                    if (!p.multiple && value && typeof value !== "string") {
                        p.handleAsyncChange(p.searchWord, value.id, value);
                    }
                }
            }}
            options={options}
            renderInput={params => (
                <TextField
                    {...params}
                    label={p.label}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}
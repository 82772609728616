import { useState } from 'react';
import { useNavigate } from 'react-router';
import { makeStyles, createStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import { useLoadData } from '../utils';
import { getApiWorkout_typeWorkouttypeid, putApiWorkout_typeWorkouttypeid, postApiWorkout_type } from '../services/workout.type';
import { WorkoutType } from '../models/WorkoutType';
import { Formik, Form, FieldArray } from 'formik';
import { urls, route } from '../route';
import { kind } from '../models/kind-model';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
        },
    }),
);

export const ExerciseTypesEdit = () => {
    const classes = useStyles();
    const history = useNavigate();
    const p = useParams();
    const [workout, setWorkout] = useState<WorkoutType>();
    useLoadData(async () => {
        if (p.id) {
            const result = await getApiWorkout_typeWorkouttypeid(+p.id);
            setWorkout(result);
        } else {
            setWorkout({
                id: 0,
                name: "",
                videoMode: 0,
                kind: kind.exercises,
                hasProgression: false,
                isCustomProgression: false,
                editable: false,
                noSchedule: false
            });
        }
    });

    function validate(o: WorkoutType) {
        const errors = {} as any;
        if (o.videoMode) {
            if (o.videoMode === 1 || o.videoMode === 0) {

            } else {
                errors.videoMode = "Video Mode has only two values . 0 or 1"
            }
        }
        return errors;
    }
    async function onSubmit(o: WorkoutType) {
        const progression = {
            id: o.progression?.id ?? 0, progression: o.progression?.progression?.map(e => ({
                reps: e.reps === 0 || e.reps === "" ? undefined : e.reps,
                sets: e.sets === 0 || typeof e.sets == "string" ? undefined : e.sets,
                cycles: e.cycles === 0 || typeof e.cycles == "string" ? undefined : e.cycles,
                hold: e.hold === 0 || typeof e.hold == "string" ? undefined : e.hold,
                order: e.order
            }))
        }
        const workoutPayload = { ...o, progression }
        let { id, editable, ...payload } = workoutPayload
        try {
            if (p.id) {
                await putApiWorkout_typeWorkouttypeid(+p.id, payload);
            } else {
                await postApiWorkout_type(payload);
            }
            history(route(urls.exerciseTypeList));
        } catch (e) {
            console.log(e);
        }
    }
    if (!workout) {
        return <div></div>;
    }
    return <Container>
        <Paper className={classes.root}>
            <Formik<WorkoutType>
                initialValues={workout}
                onSubmit={onSubmit}
                validate={validate}
            >
                {({ values, handleChange, errors }) => {
                    return <Form>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4" gutterBottom>{p.id ? `Editing ${workout.name}` : "Add new Exercise Type"}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={6}>
                                    <Grid item sm={4}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Button
                                            onClick={e => {
                                                history(route(urls.exerciseTypeList));
                                            }}
                                            fullWidth
                                            variant="outlined"
                                            color="primary"
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{ margin: 0 }} container spacing={3}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Name"
                                        variant="outlined"
                                        value={values.name}
                                        id={"name"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        type="number"
                                        label="Video Mode"
                                        variant="outlined"
                                        value={values.videoMode}
                                        id={"videoMode"}
                                        onChange={handleChange}
                                        error={errors.videoMode ? true : false}
                                        helperText={errors.videoMode ? errors.videoMode : null}
                                    />
                                </Grid>
                                {p.id ? <div></div> :
                                    <Grid item xs={12} sm={3}>
                                        <div style={{ float: "left" }}>
                                            Custom Progression: <Checkbox id={"isCustomProgression"} inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} value={values.isCustomProgression} checked={values.isCustomProgression} onChange={handleChange} />
                                        </div>
                                    </Grid>}
                                {values.isCustomProgression ? "" :
                                    <Grid item xs={12} sm={8} >
                                        <FieldArray
                                            name="progression.progression"
                                            render={arrayHelpers => (
                                                <Grid container spacing={4}>
                                                    {values.progression?.progression && values.progression?.progression.length > 0 ? (
                                                        values.progression?.progression?.map((e, index: number) => (
                                                            <Grid item xs={12} key={index} >
                                                                Progression {e.order = index + 1}:
                                                                <TextField label="Reps" type="string" name={`progression.progression.${index}.reps`} value={e.reps ?? ""} onChange={handleChange} variant="outlined" style={{ marginLeft: '1em', marginRight: '1em', width: '5.5em' }} />
                                                                <TextField label="Sets" type="number" name={`progression.progression.${index}.sets`} value={e.sets ?? ""} onChange={handleChange} variant="outlined" style={{ marginLeft: '1em', marginRight: '1em', width: '5.5em' }} />
                                                                <TextField label="Hold" type="number" name={`progression.progression.${index}.hold`} value={e.hold ?? ""} onChange={handleChange} variant="outlined" style={{ marginLeft: '1em', marginRight: '1em', width: '5.5em' }} />
                                                                <TextField label="Cycles" type="number" name={`progression.progression.${index}.cycles`} value={e.cycles ?? ""} onChange={handleChange} variant="outlined" style={{ marginLeft: '1em', marginRight: '1em', width: '5.5em' }} />
                                                                <IconButton aria-label="delete" onClick={() => arrayHelpers.remove(index)}>
                                                                    <Icon>delete</Icon>
                                                                </IconButton>
                                                                {index + 1 === values.progression?.progression?.length && values.progression?.progression?.length !== 3 ? <IconButton aria-label="delete" onClick={() => arrayHelpers.insert(index + 1, { reps: undefined, sets: undefined, hold: undefined, cycles: undefined })}>
                                                                    <Icon>add</Icon>
                                                                </IconButton> : <div></div>}
                                                            </Grid>
                                                        ))
                                                    ) : (
                                                        <Grid item xs={12}>
                                                            <Button color="primary" variant="contained" onClick={() => arrayHelpers.push({ reps: undefined, sets: undefined, hold: undefined, cycles: undefined })}>
                                                                Add a progression
                                                            </Button>
                                                        </Grid>
                                                    )
                                                    }
                                                </Grid>
                                            )}
                                        />

                                    </Grid>}
                            </Grid>
                        </Grid>
                    </Form>
                }}
            </Formik>
        </Paper>
    </Container >
}
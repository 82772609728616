import {  useState } from 'react';
import { useNavigate } from 'react-router';
import { makeStyles, createStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import { useLoadData } from '../utils';
import { getApiWorkoutWorkoutid, putApiWorkoutWorkoutid, postApiWorkout, getApiWorkoutList } from '../services/workout';
import { Formik, Form } from 'formik';
import { Workout } from '../models/Workout'
import { kind } from '../models/kind-model'
import { WorkoutWithInfo } from '../models/workout-info-and-progression'
import { AsyncAutoCompleteTable } from '../components/asyncAutoCompleteTable';
import { WorkoutBasic } from '../models/WorkoutBasic';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwordIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

const useStyles = makeStyles((theme: { spacing: (arg0: number, arg1: number) => any; }) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
        },
    }),
);

declare module "../models/childInfo" {
    export interface childInfo {
        durationMin: number,
        durationMax: number,
        duration: number,
        dailyRoutine: boolean
    }
}

const validateForm = Yup.object().shape({
    duration: Yup.number().min(1, 'Value must be 1 or greater than 1'),
})

export const PhaseEdit = () => {
    const history = useNavigate();
    const p = useParams();
    const classes = useStyles();
    const [phase, setPhase] = useState<WorkoutWithInfo>();
    const [programParent, setProgramParent] = useState<Workout>();

    useLoadData(async () => {
        if (p.id) {
            const result = await getApiWorkoutWorkoutid(+p.id, {});
            setPhase(result);
        } else {
            setPhase({
                workoutTypeId: {
                    id: 0,
                    name: "",
                    hasProgression: false,
                    isCustomProgression: false,
                    noSchedule: false
                },
                hidden: false,
                durationMin: 0,
                durationMax: 0,
                kind: kind.phase,
                level: 0,
                mediaKey: "mediaKey",
                downloadUrl: "downloadUrl",
                name: "",
                id: 1,
                type: {
                    id: 1,
                    name: "",
                    videoMode: 0,
                    hasProgression: false,
                    isCustomProgression: false,
                    noSchedule: false
                },
                workoutChildren: [],
            });
        }
        if (p.idProgram) {
            const result = await getApiWorkoutWorkoutid(+p.idProgram, {});
            setProgramParent(result);
        }
    });
    if (!phase) {
        return <div></div>;
    }
    async function onSubmit(workoutPayload: Workout) {
        const workoutChildren = workoutPayload.workoutChildren?.map(e => {
            return {
                id: e.id,
                childInfo: {
                    durationMin: e.childInfo?.durationMin ? e.childInfo!.durationMin : e.durationMin,
                    durationMax: e.childInfo?.durationMax ? e.childInfo!.durationMax : e.durationMax,
                    duration: e.childInfo?.duration ? e.childInfo!.duration : e.duration!,
                    dailyRoutine: e.childInfo?.dailyRoutine ? e.childInfo?.dailyRoutine! : false
                }
            };
        }) ?? [];

        const downloadUrl = workoutPayload.downloadUrl ?? ""
        const duration = workoutPayload.duration ?? 0
        const details = workoutPayload.details ?? ""
        const workoutTypeId = workoutPayload.type!.id
        const workout = { ...workoutPayload, joints: undefined, equipments: undefined, workoutTypeId, workoutChildren, progression: undefined, videoDuration: 0, duration, downloadUrl, details, relatedWorkouts: undefined }

        var { type, id, scheduled, userWorkout, isCustom, workoutProgression, childCount, grandChildCount, ...payload } = workout;
        let savedPhase;
        if (p.id) {
            await putApiWorkoutWorkoutid(+p.id, payload);
        } else {
            savedPhase = await postApiWorkout(payload);

        }

        if (programParent) {
            const nameProgram = programParent.name;
            const workoutChildrenProgram = programParent.workoutChildren?.map(e => ({ id: e.id })) ?? [];
            const jointsProgram = workoutPayload.joints?.map(e => {
                if (typeof e !== "number") {
                    return e.id;
                }
                return e;
            }) ?? [];
            const downloadUrlProgram = programParent.downloadUrl ?? ""
            const detailsProgram = programParent.details ?? ""
            const workoutTypeIdProgram = programParent.type!.id
            const workoutProgram = { ...workoutPayload, name: nameProgram, kind: kind.programs, workoutChildren: workoutChildrenProgram, joints: jointsProgram, equipments: undefined, workoutTypeId: workoutTypeIdProgram, progression: undefined, videoDuration: 0, duration: 0, downloadUrl: downloadUrlProgram, details: detailsProgram, relatedWorkouts: undefined }

            var { type, id, scheduled, userWorkout, isCustom, workoutProgression, childCount, grandChildCount, ...payloadProgram } = workoutProgram;
            if (savedPhase) {
                await putApiWorkoutWorkoutid(+p.idProgram!, {
                    ...payloadProgram, workoutChildren: [...workoutChildrenProgram, { id: savedPhase?.id }]
                });
            }
            history(`/app/programs/edit/${p.idProgram}`);
        }
    }
    return <Container>
        <Paper className={classes.root}>
            <Formik<WorkoutWithInfo>
                initialValues={phase}
                onSubmit={onSubmit}
            >
                {({ values, handleChange, setValues, errors, touched }) => {
                    const handleClick = (id: number) => {
                        let newArray = values.workoutChildren?.filter(e => e.id !== id);
                        setValues({ ...values, workoutChildren: newArray })
                    }
                    const handleClickUp = (index: number) => {
                        const newArray: WorkoutBasic[] = [...values.workoutChildren ?? []];
                        if (index !== 0) {
                            newArray[index] = values.workoutChildren![index - 1]
                            newArray[index - 1] = values.workoutChildren![index]
                            setValues({ ...values, workoutChildren: newArray })
                        }
                    }
                    const handleClickDown = (index: number) => {
                        const newArray: WorkoutBasic[] = [...values.workoutChildren ?? []];
                        if (index !== values.workoutChildren!.length - 1) {
                            newArray[index] = values.workoutChildren![index + 1]
                            newArray[index + 1] = values.workoutChildren![index]
                            setValues({ ...values, workoutChildren: newArray })
                        }
                    }
                    const resetProgressionId = (id: number) => {
                        const modifiedWorkoutChildren: any = values.workoutChildren?.map((workoutChildren, index) => {
                            if (id === index) {
                                return {
                                    ...workoutChildren, childInfo: {
                                        ...workoutChildren.childInfo,
                                        id: null,
                                        dailyRoutine: !workoutChildren.childInfo?.dailyRoutine
                                    }
                                };
                            }
                            return { ...workoutChildren };
                        });
                        setValues({ ...values, workoutChildren: modifiedWorkoutChildren })
                    }
                    const handleReset = (e: any, id: number) => {
                        resetProgressionId(id);
                        handleChange(e);
                    }
                    return <Form>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4" gutterBottom>{(p.id && p.idProgram) ? `Editing ${phase.name}` : "Add new Phase"}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={6}>
                                    <Grid item sm={4}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Button
                                            onClick={_e => {
                                                history(`/app/programs/edit/${p.idProgram}`);
                                            }}
                                            fullWidth
                                            variant="outlined"
                                            color="primary"
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{ margin: 0 }} container spacing={3}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Name"
                                        variant="outlined"
                                        value={values.name}
                                        id={"name"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        type="number"
                                        label="Duration"
                                        variant="outlined"
                                        id={"duration"}
                                        onChange={handleChange}
                                        error={(errors.duration && touched.duration) ? true : false}
                                        helperText={errors.duration ? errors.duration : null}
                                        value={values.duration ? values.duration : 0}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} style={{ marginLeft: 1 }}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Frequency Min"
                                        variant="outlined"
                                        value={values.durationMin}
                                        id={"durationMin"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Frequency Max"
                                        variant="outlined"
                                        value={values.durationMax}
                                        id={"durationMax"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        required
                                        label="Details"
                                        variant="outlined"
                                        value={values.details || ""}
                                        id={"details"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AsyncAutoCompleteTable
                                        label="Select Routines"
                                        multiple={true}
                                        searchWord="name"
                                        value={[]}
                                        defaultValue={values.workoutChildren}
                                        suggestions={() => getApiWorkoutList({ kind: [kind.routines] })}
                                        onChange={(newChildren) => {
                                            let newArray: any[] = values.workoutChildren!.map(e => e)
                                            newArray.push(newChildren)
                                            setValues({ ...values, workoutChildren: newArray })
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {values.workoutChildren?.map((w, wIndex) =>
                                    < CardContent key={wIndex} style={{ paddingBottom: "2em", marginTop: "1.5em", borderStyle: "solid", borderColor: "#D3D3D3", borderWidth: "1px", borderRadius: "5px" }}>
                                        <Typography component="h2" style={{ fontSize: "1.2em" }}>
                                            {w.name}
                                            <IconButton aria-label="delete" style={{ float: 'right', display: 'flex', lineHeight: '40px' }} size="small" onClick={() => handleClick(w.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Typography>

                                        <IconButton aria-label="move up" size="small" style={{ float: "left" }} onClick={() => handleClickUp(wIndex)}>
                                            <ArrowUpwordIcon />
                                        </IconButton>

                                        <div style={{ flexDirection: 'row', display: 'flex', marginTop: 25 }}>
                                            <div style={{ maxWidth: '100%' }}>
                                                <Typography key={w.id} variant="body1" color="textSecondary" style={{ paddingLeft: "3em", flexDirection: 'row', }}>
                                                    {w.details}<br />
                                                    Joints: {w.joints?.map(j => <span key={j.id}>{j.name}</span>)}<br /><br />
                                                </Typography>
                                            </div>
                                        </div>

                                        <IconButton aria-label="move down" size="small" style={{ float: "left" }} onClick={() => handleClickDown(wIndex)}>
                                            <ArrowDownwardIcon />
                                        </IconButton>
                                        {programParent?.type?.name === "Pain and Injury" ?
                                            <div style={{ float: "right", marginTop: -10 }}>
                                                Daily Routine: <Checkbox inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} value={w.childInfo?.dailyRoutine} checked={w.childInfo?.dailyRoutine} onChange={(w: any) => {
                                                    handleReset(w, wIndex)
                                                }} />
                                            </div> :
                                            <></>
                                        }
                                    </CardContent>
                                )}
                            </Grid>
                        </Grid>
                    </Form>
                }}
            </Formik>
        </Paper>
    </Container>
}
import { GetSocial } from "../models/GetSocial";
import { Users } from "../models/Users";
import { UserProfilePayload } from "../models/UserProfilePayload";
import { UserProfile } from "../models/UserProfile";
import { EmailSignIn } from "../models/alt-EmailSignIn";
import { FacebookSignIn } from "../models/alt-FacebookSignIn";
import { GoogleSignIn } from "../models/alt-GoogleSignIn";
import { DeviceCodeSignIn } from "../models/alt-DeviceCodeSignIn";
import { AppleSignIn } from "../models/alt-AppleSignIn";
import { LoginResponse } from "../models/LoginResponse";
import { EmailSignUp } from "../models/alt-EmailSignUp";
import { DeviceCodeSignUp } from "../models/alt-DeviceCodeSignUp";
import { ResetPassSchema } from "../models/ResetPassSchema";
import { UpdateUserPayload } from "../models/UpdateUserPayload";
import { UpdatePassSchema } from "../models/UpdatePassSchema";
import { baseUrl, headers } from "./config";
import { appendFormData, fetchAndParse, addQueryParameter } from "./utils";
export function getApiUserGetsocialtoken(): Promise<GetSocial> {
    const url = new URL(`/api/user/getsocial-token`, baseUrl);
    return fetchAndParse<GetSocial>(url.toString(), { method: "GET", headers });
}
export function getApiUserList(): Promise<Users> {
    const url = new URL(`/api/user/list`, baseUrl);
    return fetchAndParse<Users>(url.toString(), { method: "GET", headers });
}
export function getApiUserUserid(userId: number): Promise<UserProfilePayload> {
    const url = new URL(`/api/user/${userId}`, baseUrl);
    return fetchAndParse<UserProfilePayload>(url.toString(), { method: "GET", headers });
}
export function getApiUserMeProfile(): Promise<UserProfilePayload> {
    const url = new URL(`/api/user/me/profile`, baseUrl);
    return fetchAndParse<UserProfilePayload>(url.toString(), { method: "GET", headers });
}
export function putApiUserMeProfile(body?: UserProfile): Promise<UserProfilePayload> {
    const url = new URL(`/api/user/me/profile`, baseUrl);
    return fetchAndParse<UserProfilePayload>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers });
}
export function getApiUserUnsubscribeEmailToken(token: string): Promise<string> {
    const url = new URL(`/api/user/unsubscribe/email/${token}`, baseUrl);
    return fetchAndParse<string>(url.toString(), { method: "GET", headers });
}
export function postApiUserLogin(body?: EmailSignIn | FacebookSignIn | GoogleSignIn | DeviceCodeSignIn | AppleSignIn): Promise<LoginResponse> {
    const url = new URL(`/api/user/login`, baseUrl);
    return fetchAndParse<LoginResponse>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}
export function postApiUserRegister(body?: EmailSignUp | DeviceCodeSignUp): Promise<number> {
    const url = new URL(`/api/user/register`, baseUrl);
    return fetchAndParse<number>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}
export function postApiUserResetpassword(body?: ResetPassSchema): Promise<number> {
    const url = new URL(`/api/user/reset-password`, baseUrl);
    return fetchAndParse<number>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}
export function postApiUserUpdate(body?: UpdateUserPayload): Promise<string> {
    const url = new URL(`/api/user/update`, baseUrl);
    return fetchAndParse<string>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}
export function postApiUserUpdatepassword(body?: UpdatePassSchema): Promise<number> {
    const url = new URL(`/api/user/update-password`, baseUrl);
    return fetchAndParse<number>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}
export function putApiUsersUserid(userId: number): Promise<number> {
    const url = new URL(`/api/users/${userId}`, baseUrl);
    return fetchAndParse<number>(url.toString(), { method: "PUT", body: null, headers });
}
export function putApiUserMeProfilepicture(picture: File): Promise<string> {
    const localHeaders = { ...headers };
    delete localHeaders["Content-Type"];
    const data = new FormData();
    appendFormData(data, "picture", picture);
    const url = new URL(`/api/user/me/profile-picture`, baseUrl);
    return fetchAndParse<string>(url.toString(), { method: "PUT", body: data, headers: localHeaders });
}
export function putApiUserMeUpdate(body?: UserProfilePayload): Promise<UserProfilePayload> {
    const url = new URL(`/api/user/me/update`, baseUrl);
    return fetchAndParse<UserProfilePayload>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers });
}

import React, { useState, useRef } from 'react'
import { useNavigate } from "react-router";
import { deleteApiWorkoutWorkoutid, getApiStatisticsProgram, getApiWorkoutList } from '../services/workout';
import MaterialTable, { Options } from '@material-table/core';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import { urls, route } from '../route';
import { loadTableData } from './loaders';
import { Workout } from '../models/Workout';
import { confirmAndDelete } from '../utils';
import { kind } from '../models/kind-model';

import ReactExport from "react-export-excel";
import icon from "../export-excel.png";
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const StatusDisplay = {
    0: "Progress",
    1: "Done",
    2: "Waiting for re-schedule"
}
const download = async () => {
    const data = await getApiStatisticsProgram({})
    console.log(data);
    const rows = data.items.map(s => ({
        email: s.email,
        programName: s.programName,
        phaseName: s.phaseName,
        score: s.score,
        startDate: s.startDate ? moment(s.startDate).format('L') : null,
        phaseStatus: StatusDisplay[s.phaseScheduleStatus],
        routineScheduleStatus: StatusDisplay[s.routineScheduleStatus],
        routineDate: s.routineDate ? moment(s.routineDate).format('L') : null,
    }));
    const excelFile = new ExcelFile({
        filename: "program-statistics",
        fileExtension: "xlsx",
        children: [<ExcelSheet data={rows} name="Programs">
            <ExcelColumn label="Email" value="email" />
            <ExcelColumn label="Program" value="programName" />
            <ExcelColumn label="Phase" value="phaseName" />
            <ExcelColumn label="Assessment Score" value="score" />
            <ExcelColumn label="Phase Start" value="startDate" />
            <ExcelColumn label="Phase Status" value="phaseStatus" />
            <ExcelColumn label="Routine Date" value="routineDate" />
            <ExcelColumn label="Routine Status" value="routineScheduleStatus" />
        </ExcelSheet>]
    });
    excelFile.handleDownload();
};

export const ProgramsList = () => {
    const history = useNavigate();
    const ref = useRef<any>();
    const [hidden, setHidden] = useState(false)
    const [pagination,] = useState<Options<Workout>>({
        actionsColumnIndex: -1,
        selection: true,
        sorting: false,
        pageSize: 10,
        columnsButton: true,
    });
    return <div>
        <MaterialTable<Workout>
            title="Programs"
            options={pagination}
            tableRef={ref}
            columns={[
                { title: "Id", field: "id", cellStyle: { width: "10%" } },
                { title: "Name", field: "name", cellStyle: { width: "25%" } },
                { title: "Type", render: (e: Workout) => <><div>{e.type!.name}</div></>, cellStyle: { width: "25%" } },
                { title: "# of Phases", field: "childCount", cellStyle: { width: "15%" } },
                { title: "# of Routines", field: "grandChildCount", cellStyle: { width: "15%" } },
                {
                    title: 'Tools',
                    render: (e) => (<>
                        <div style={{ float: "left" }}>
                            <div style={{ float: e.hidden ? "right" : "left", width: "50%", visibility: e.hidden ? "visible" : "hidden", backgroundColor: "#e6e6e6", textAlign: "center", fontWeight: "bold" }}>
                                <span style={{ writingMode: "vertical-rl", textOrientation: "upright" }}>HIDDEN</span>
                            </div>
                            <div style={{ float: e.hidden ? "left" : "right", width: "50%" }}>
                                <IconButton onClick={() => history(`/app/programs/edit/${e.id}`)} style={{ float: "right", top: 20 }} ><Icon>edit</Icon></IconButton>
                                <IconButton onClick={confirmAndDelete(e, deleteApiWorkoutWorkoutid, ref)} style={{ float: "right", top: 15 }}><Icon>delete</Icon></IconButton>
                            </div>
                        </div>
                    </>), cellStyle: { width: "5%" }
                }
            ]}
            data={loadTableData(() => getApiWorkoutList({ kind: [kind.programs], includeHidden: hidden }))}
            actions={[{
                icon: "delete",
                tooltip: "Delete",
                onClick: () => { }
            }, {
                icon: "visibilityOff",
                tooltip: "View Hidden",
                isFreeAction: true,
                onClick: () => { }

            },
            {
                icon: "add",
                tooltip: "Add New",
                isFreeAction: true,
                onClick: () => history(route(urls.programsAdd)),
            },
            {
                tooltip: "Export CSV",
                isFreeAction: true,
                disabled: false,
                icon: "export-csv",
                onClick: download
            }]}
            components={{
                Action: props => {
                    if (props.action.icon === "export-csv") {
                        return <IconButton onClick={props.action.onClick} size="small">
                            <img alt='export-excel' src={String(icon)} style={{
                                width: "24px",
                                height: "24px",
                            }} />
                        </IconButton>
                    }
                    if (props.action.icon === "visibilityOff")
                        return <Checkbox inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} value={hidden} checked={hidden}
                            onClick={() => {
                                setHidden(!hidden)
                                if (ref.current) {
                                    ref.current.onQueryChange();
                                }
                            }} />
                    else if (props.action.icon === "delete") {
                        const element = props.action;
                        return <IconButton aria-label={element.icon} size="small"
                            onClick={async () => {
                                const confirmation = window.confirm("Are you sure you want to delete the selected items?")
                                if (confirmation && Array.isArray(props.data)) {
                                    for (const i of props.data) {
                                        await deleteApiWorkoutWorkoutid(i.id);
                                    }
                                    if (ref.current) {
                                        ref.current.onQueryChange();
                                    }
                                }
                            }}>
                            <Icon>{element.icon}</Icon>
                        </IconButton>
                    } else {
                        const element = props.action;
                        return <IconButton aria-label={element.icon} size="small"
                            onClick={element.onClick}>
                            <Icon>{element.icon}</Icon>
                        </IconButton>
                    }
                }
            }}
        />
    </div>
}
import { useEffect, useState } from "react";
import { localStorageUserKey } from "./services/config";
import { useNavigate } from "react-router";
import { urls, route } from "./route";

export function useTitle(docTitle: string) {
    const [title, setTitle] = useState(docTitle);
    useEffect(() => {
        document.title = title;
    }, [title]);
    return setTitle;
}
export function useLoadData(fn: () => Promise<void>, deps: readonly any[] = []) {
    const hist = useNavigate();
    useEffect(() => {
        fn().catch(e => {
            if (e.response && e.response.status === 401) {
                window.localStorage.removeItem(localStorageUserKey);
                hist(route(urls.login));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
export function withPreventDefault(fn: (e: React.FormEvent<HTMLFormElement>) => void) {
    return function (e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        fn(e);
    };
}
export function updateState<T, K extends keyof T>(value: T, set: (o: T) => void, key: K) {
    return function (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        set({ ...value, [key]: e.target.value });
    };
}
export function confirmAndDelete<T extends { id?: number, name: string }>(e: T, fn: (id: number) => Promise<boolean>, ref: React.MutableRefObject<any>) {
    return async () => {
        let confirmation = window.confirm(`Are you sure you want to delete ${e.name}?`);
        if (confirmation) {
            await fn(e.id!);
        }
        if (ref.current) {
            ref.current.onQueryChange();
        }
    }
}
export function userConfirmDelete<T extends { id?: number }>(e: T, fn: (id: number) => Promise<boolean>, ref: React.MutableRefObject<any>) {
    return async () => {
        let confirmation = window.confirm(`Are you sure you want to delete this user`);
        if (confirmation) {
            if (e.id) {
                await fn(e.id);
            }
        }
        if (ref.current) {
            ref.current.onQueryChange();
        }
    }
}

import React, { useEffect } from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createRoot } from 'react-dom/client';

const theme = createTheme({
    components: {
        MuiListItemText: {
            styleOverrides: {
                "root": {
                    flex: "1 1 auto",
                    minWidth: 0,
                }
            },
        },
        MuiListItem: {
            styleOverrides: {
                "root": {
                    borderRadius: '3px',
                    "&$selected": {
                        "background": "#5EA1DD"
                    }
                }
            },
        },
        MuiContainer: {
            styleOverrides: {
                "root": {
                    boxSizing: 'unset',
                    width: 'auto',
                },
                maxWidthLg: {
                    "@media (min-width:1280px)": {
                        maxWidth: 'inherit'

                    }
                }
            },
        },
    },
})

function AppWithCallbackAfterRender() {
    useEffect(() => {
        console.log('rendered');
    });
    return (
        <React.StrictMode>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </BrowserRouter>
        </React.StrictMode>
    )
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<AppWithCallbackAfterRender />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
import { Joints } from "../models/Joints";
import { JointPayload } from "../models/JointPayload";
import { Joint } from "../models/Joint";
import { baseUrl, headers } from "./config";
import { fetchAndParse, addQueryParameter } from "./utils";
export function getApiJointList(): Promise<Joints> {
    const url = new URL(`/api/joint/list`, baseUrl);
    return fetchAndParse<Joints>(url.toString(), { method: "GET", headers });
}
export function getApiJointJointid(jointId: number): Promise<JointPayload> {
    const url = new URL(`/api/joint/${jointId}`, baseUrl);
    return fetchAndParse<JointPayload>(url.toString(), { method: "GET", headers });
}
export function putApiJointJointid(jointId: number, body?: Joint): Promise<JointPayload> {
    const url = new URL(`/api/joint/${jointId}`, baseUrl);
    return fetchAndParse<JointPayload>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers });
}
export function deleteApiJointJointid(jointId: number): Promise<boolean> {
    const url = new URL(`/api/joint/${jointId}`, baseUrl);
    return fetchAndParse<boolean>(url.toString(), { method: "DELETE", headers });
}
export function postApiJoint(body?: Joint): Promise<JointPayload> {
    const url = new URL(`/api/joint`, baseUrl);
    return fetchAndParse<JointPayload>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}

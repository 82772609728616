import { toast } from "react-toastify";
import { localStorageUserKey } from "./config";

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export function addQueryParameter(url: URL, name: string, value: null | undefined | string | number | boolean | number[] | string[] | boolean[]) {
    if (value == null) return;
    if (Array.isArray(value)) {
        for (let o of value) {
            url.searchParams.append(name, o?.toString());
        }
    } else {
        url.searchParams.append(name, value.toString());
    }
}

export function appendFormData(data: FormData, name: string, file: File) {
    data.append(name, file)
}

export async function delay(ms: number) {
    return new Promise<void>((r) => setTimeout(r, ms));
}


export enum SignInType {
    Regular,
    Facebook,
    Google
}

export enum EvaluationStatus {
    STARTED,
    FINALIZED
}

export enum AssessmentStatus {
    STARTED,
    SUCCESS,
    FAIL
}

export function isFetchError(o: unknown): o is { status: number, statusText: string, json: unknown } {
    return !!(typeof o === "object" && o && 'status' in o && 'statusText' in o && 'json' in o);
}

export async function fetchAndParse<T>(input: RequestInfo, init?: RequestInit): Promise<T> {
    const response = await fetch(input, init)
    if (response.status !== 200) {
        if (response.status === 401) {
            window.localStorage.removeItem(localStorageUserKey);
            window.location.href = "/login";
        }
        const json = await response.json();
        console.log(json);
        toast(json.message, {
            style: {
                backgroundColor: "#5EA1DD",
                color: "white",
            },
            type: "success",
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        // eslint-disable-next-line no-throw-literal
        throw {
            status: response.status,
            statusText: response.statusText,
            response,
            json,
            toString() {
                return `${this.status} - ${this.json.message || this.statusText}`;
            }
        };
    }
    const data = await response.json();
    return data as T;
}
export const tablePages = {
    userPage: {
        page: 0,
        pageSize: 20,
    },
    assessmentPage: {
        page: 0,
        pageSize: 20,
    },
    equipmentPage: {
        page: 0,
        pageSize: 20,
    },
    jointsPage: {
        page: 0,
        pageSize: 20,
    },
    singleExercisePage: {
        page: 0,
        pageSize: 20,
    },
    exerciseTypePage: {
        page: 0,
        pageSize: 20,
    },
    routinesPage: {
        page: 0,
        pageSize: 20,
    },
    routineTypesPage: {
        page: 0,
        pageSize: 20,
    },
}
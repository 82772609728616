import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { makeStyles, createStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CardContent from '@mui/material/CardContent';
import { useLoadData } from '../utils';
import { getApiWorkoutWorkoutid, putApiWorkoutWorkoutid, postApiWorkout, putApiWorkoutWorkoutidPicture } from '../services/workout';
import { Formik, Form } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowUpwordIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import IconButton from '@mui/material/IconButton';
import { urls, route } from '../route';
import { AsyncAutocomplete } from '../components/asyncAutocomplete';
import { Workout } from '../models/Workout'
import { kind } from '../models/kind-model'
import { WorkoutBasic } from '../models/WorkoutBasic';
import { WorkoutWithInfo } from '../models/workout-info-and-progression'
import { getApiJointList } from '../services/joint';
import { WorkoutPayload } from '../models/WorkoutPayload';
import { getApiPrograms_typeList } from '../services/workout-type-filter';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: { spacing: (arg0: number, arg1: number) => any; }) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
        },
    }),
);
export const ProgramsEdit = () => {
    const history = useNavigate();
    const p = useParams();
    const classes = useStyles();
    const [program, setProgram] = useState<WorkoutWithInfo>();
    const [imgUrl, setImgUrl] = useState("");
    useLoadData(async () => {
        if (p.id) {
            const result = await getApiWorkoutWorkoutid(+p.id, {});
            setProgram(result);
            setImgUrl(result.picture ?? "");
        } else {
            setProgram({
                workoutTypeId: {
                    id: 0,
                    name: "",
                    hasProgression: false,
                    isCustomProgression: false,
                    noSchedule: false
                },
                hidden: false,
                durationMin: 0,
                durationMax: 0,
                kind: kind.programs,
                level: 0,
                name: "",
                id: 1,
                mediaKey: "",
                type: {
                    id: 1,
                    name: "",
                    videoMode: 0,
                    hasProgression: false,
                    isCustomProgression: false,
                    noSchedule: false
                },
                workoutChildren: [],
                relatedWorkouts: [],
            });
        }
    });
    if (!program) {
        return <div></div>;
    }
    async function onSubmit(workoutPayload: Workout) {
        const joints = workoutPayload.joints?.map(e => {
            if (typeof e !== "number") {
                return e.id;
            }
            return e;
        }) ?? [];
        const workoutChildren = workoutPayload.workoutChildren?.map(e => {
            return {
                id: e.id
            };
        }) ?? [];
        const downloadUrl = workoutPayload.downloadUrl ?? ""
        const details = workoutPayload.details ?? ""
        const workoutTypeId = workoutPayload.type!.id ?? ""
        const workout = { ...workoutPayload, joints, equipments: undefined, workoutTypeId, workoutChildren, videoDuration: 0, duration: 0, downloadUrl, details, relatedWorkouts: undefined };
        var { type, id, scheduled, userWorkout, isCustom, workoutProgression, childCount, grandChildCount, ...payload } = workout;
        console.log(payload);
        if (p.id) {
            await putApiWorkoutWorkoutid(+p.id, payload);
        } else {
            await postApiWorkout(payload);
        }
        history(route(urls.programsList));
    }
    return <Container>
        <Paper className={classes.root}>
            <Formik<WorkoutWithInfo>
                initialValues={program}
                onSubmit={onSubmit}
            >
                {({ values, handleChange, setValues, errors, touched }) => {
                    const handleCapture = async (e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.files) {
                            const file = e.target.files[0];
                            const result = await putApiWorkoutWorkoutidPicture(values.id, file);
                            setValues({ ...values, picture: result.picture });
                            setImgUrl(result.picture);
                        }
                    }
                    const handleClick = (id: number) => {
                        let newArray = values.workoutChildren?.filter(e => e.id !== id);
                        setValues({ ...values, workoutChildren: newArray })
                    }
                    const handleAsyncChange = (key: keyof WorkoutPayload, value: number | number[]) => {
                        setValues({ ...values, [key]: value });
                    }
                    const forField = <K extends keyof WorkoutPayload>(key: K) => ({
                        handleAsyncChange,
                        defaultValue: values[key],
                        searchWord: key
                    })
                    const handleClickUp = (index: number) => {
                        const newArray: WorkoutBasic[] = [...values.workoutChildren ?? []];
                        if (index !== 0) {
                            newArray[index] = values.workoutChildren![index - 1]
                            newArray[index - 1] = values.workoutChildren![index]
                            setValues({ ...values, workoutChildren: newArray })
                        }
                    }
                    const handleClickDown = (index: number) => {
                        const newArray: WorkoutBasic[] = [...values.workoutChildren ?? []];
                        if (index !== values.workoutChildren!.length - 1) {
                            newArray[index] = values.workoutChildren![index + 1]
                            newArray[index + 1] = values.workoutChildren![index]
                            setValues({ ...values, workoutChildren: newArray })
                        }
                    }
                    return <Form>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4" gutterBottom>{p.id ? `Editing ${program.name}` : "Add new Program"}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={6}>
                                    <Grid item sm={4}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Button
                                            onClick={e => {
                                                history(route(urls.programsList));
                                            }}
                                            fullWidth
                                            variant="outlined"
                                            color="primary"
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div style={{ float: "right", marginLeft: 20 }}>
                                            Hide: <Checkbox id={"hidden"} inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} value={values.hidden} checked={values.hidden} onChange={handleChange} />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{ margin: 0 }} container spacing={3}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Name"
                                        variant="outlined"
                                        value={values.name}
                                        id={"name"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Media Key"
                                        variant="outlined"
                                        value={values.mediaKey || ""}
                                        id={"mediaKey"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        required
                                        label="Download Url"
                                        variant="outlined"
                                        value={values.downloadUrl || ""}
                                        id={"downloadUrl"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} >
                                    <AsyncAutocomplete
                                        label="Type"
                                        multiple={false}
                                        suggestions={getApiPrograms_typeList}
                                        defaultValue={values.type}
                                        searchWord="type"
                                        handleAsyncChange={(key, v, items) => {
                                            setValues({ ...values, type: items })
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AsyncAutocomplete
                                        label="Joints"
                                        multiple={true}
                                        suggestions={getApiJointList}
                                        {...forField("joints")}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        required
                                        label="Details"
                                        variant="outlined"
                                        value={values.details || ""}
                                        id={"details"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={4} >
                                            <div style={{ marginBottom: "1em" }}>
                                                <input
                                                    accept="image/*"
                                                    style={{ display: "none" }}
                                                    id="raised-button-file"
                                                    onChange={handleCapture}
                                                    type="file"
                                                />
                                                <label htmlFor="raised-button-file">
                                                    <Button variant="contained" component="span">
                                                        Upload Image <CloudUploadIcon style={{ paddingLeft: "0.2em" }} />
                                                    </Button>
                                                </label>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid >
                                        <img alt="" src={`${imgUrl}?t=${Date.now()}`} width="400px" height="auto" />
                                    </Grid>
                                </Grid>
                                <Grid item sm={4}>
                                    {p.id ?
                                        <Button
                                            type="button"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            onClick={() => history(`/app/phase/add/${p.id}`)}
                                        >
                                            Add Phase
                                        </Button> : <></>}
                                </Grid>
                                <Grid item xs={12}>
                                    {values.workoutChildren?.map((w, wIndex) =>
                                        < CardContent key={wIndex} style={{ paddingBottom: "1.1em", marginTop: "1.5em", borderStyle: "solid", borderColor: "#D3D3D3", borderWidth: "1px", borderRadius: "5px" }}>
                                            <Typography component="h2" style={{ fontSize: "1.2em" }}>
                                                {w.name}
                                                <IconButton aria-label="delete" style={{ float: 'right' }} size="small" onClick={() => handleClick(w.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                                <IconButton aria-label="edit" style={{ float: 'right', right: "0.6em" }} size="small" onClick={() => history(`/app/phase/edit/${w.id}/${program.id}`)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton aria-label="move up" size="small" style={{ float: "left" }} onClick={() => handleClickUp(wIndex)}>
                                                    <ArrowUpwordIcon />
                                                </IconButton>
                                                <IconButton aria-label="move down" size="small" style={{ float: "left" }} onClick={() => handleClickDown(wIndex)}>
                                                    <ArrowDownwardIcon />
                                                </IconButton>
                                            </Typography>
                                        </CardContent>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                }}
            </Formik>
        </Paper>
    </Container >
}
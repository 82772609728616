import { WorkoutTypes } from "../models/WorkoutTypes";
import { WorkoutType } from "../models/WorkoutType";
import { WorkoutTypePayload } from "../models/WorkoutTypePayload";
import { baseUrl, headers } from "./config";
import { fetchAndParse, addQueryParameter } from "./utils";
export function getApiWorkout_typeList({ includeCustomType, kind }: {
    includeCustomType?: boolean;
    kind?: "Single Exercise" | "Routines" | "Program Phase" | "Program";
}): Promise<WorkoutTypes> {
    const url = new URL(`/api/workout_type/list`, baseUrl);
    addQueryParameter(url, "includeCustomType", includeCustomType);
    addQueryParameter(url, "kind", kind);
    return fetchAndParse<WorkoutTypes>(url.toString(), { method: "GET", headers });
}
export function getApiWorkout_typeWorkouttypeid(workoutTypeId: number): Promise<WorkoutType> {
    const url = new URL(`/api/workout_type/${workoutTypeId}`, baseUrl);
    return fetchAndParse<WorkoutType>(url.toString(), { method: "GET", headers });
}
export function putApiWorkout_typeWorkouttypeid(workoutTypeId: number, body?: WorkoutTypePayload): Promise<WorkoutType> {
    const url = new URL(`/api/workout_type/${workoutTypeId}`, baseUrl);
    return fetchAndParse<WorkoutType>(url.toString(), { method: "PUT", body: JSON.stringify(body), headers });
}
export function deleteApiWorkout_typeWorkouttypeid(workoutTypeId: number): Promise<boolean> {
    const url = new URL(`/api/workout_type/${workoutTypeId}`, baseUrl);
    return fetchAndParse<boolean>(url.toString(), { method: "DELETE", headers });
}
export function postApiWorkout_type(body?: WorkoutTypePayload): Promise<WorkoutType> {
    const url = new URL(`/api/workout_type`, baseUrl);
    return fetchAndParse<WorkoutType>(url.toString(), { method: "POST", body: JSON.stringify(body), headers });
}

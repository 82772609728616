import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { makeStyles, createStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CardContent from '@mui/material/CardContent';
import Icon from '@mui/material/Icon';
import { useLoadData } from '../utils';
import { getApiWorkoutWorkoutid, putApiWorkoutWorkoutid, postApiWorkout, getApiWorkoutList, putApiWorkoutWorkoutidPicture } from '../services/workout';
import { WorkoutPayload } from '../models/WorkoutPayload';
import { Formik, Form, FieldArray } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from "@mui/icons-material/Save";
import ArrowUpwordIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import IconButton from '@mui/material/IconButton';
import UndoIcon from '@mui/material/Icon';
import { urls, route } from '../route';
import { getApiJointList } from '../services/joint';
import { AsyncAutocomplete } from '../components/asyncAutocomplete';
import { AsyncAutoCompleteTable } from '../components/asyncAutoCompleteTable';
import { Workout } from '../models/Workout'
import { kind } from '../models/kind-model'
import { WorkoutBasic } from '../models/WorkoutBasic';
import { WorkoutWithInfo } from '../models/workout-info-and-progression'
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { getApiRoutine_typeList } from '../services/workout.type.service';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';


const useStyles = makeStyles((theme: { spacing: (arg0: number, arg1: number) => any; }) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
        },
    }),
);
const validateForm = Yup.object().shape({
    duration: Yup.number().min(1, 'Value must be 1 or greater than 1'),

})
export const RoutinesEdit = () => {
    const history = useNavigate();
    const p = useParams();
    const classes = useStyles();
    const [workout, setWorkout] = useState<WorkoutWithInfo>();
    const [edit, setEdit] = useState(false);
    const [editable, setEditable] = useState(0);
    const [imgUrl, setImgUrl] = useState("");
    useLoadData(async () => {
        if (p.id) {
            const result = await getApiWorkoutWorkoutid(+p.id, {});
            setWorkout(result);
            setImgUrl(result.picture ?? "");
        } else {
            setWorkout({
                workoutTypeId: {
                    id: 0,
                    name: "",
                    hasProgression: false,
                    isCustomProgression: false,
                    noSchedule: false
                },
                hidden: false,
                durationMin: 0,
                durationMax: 0,
                kind: kind.routines,
                level: 0,
                name: "",
                id: 1,
                mediaKey: "",
                type: {
                    id: 1,
                    name: "",
                    videoMode: 0,
                    hasProgression: false,
                    isCustomProgression: false,
                    noSchedule: false
                },
                workoutChildren: [],
                relatedWorkouts: [],
            });
        }
    });
    if (!workout) {
        return <div></div>;
    }
    async function onSubmit(workoutPayload: Workout) {
        console.log(workoutPayload);
        const joints = workoutPayload.joints?.map(e => {
            if (typeof e !== "number") {
                return e.id;
            }
            return e;
        }) ?? [];
        let equipments: number[] = [];
        workoutPayload.workoutChildren?.filter(e => typeof e !== "number").forEach(e => {
            equipments.concat(e.equipments?.map(e => {
                if (typeof e !== "number") {
                    return e.id;
                }
                return e;
            }) ?? []);
        });

        const workoutChildren = workoutPayload.workoutChildren?.map(e => {
            return {
                id: e.id,
                progression: {
                    id: e.progression?.id,
                    progression: e.progression?.progression?.map(e => ({
                        reps: e.reps === 0 || e.reps === "" ? undefined : e.reps,
                        sets: e.sets === 0 || typeof e.sets == "string" ? undefined : e.sets,
                        cycles: e.cycles === 0 || typeof e.cycles == "string" ? undefined : e.cycles,
                        hold: e.hold === 0 || typeof e.hold == "string" ? undefined : e.hold,
                        order: e.order
                    })
                    ),
                    type: workoutPayload.type!.name
                }
            };
        }) ?? [];
        const relatedWorkouts = workoutPayload.relatedWorkouts?.map(e => {
            if (typeof e !== "number") {
                return e.id;
            }
            return e;
        }) ?? [];


        const videoDuration = workoutPayload.videoDuration ?? 0
        const duration = workoutPayload.duration ?? 0
        const downloadUrl = workoutPayload.downloadUrl ?? ""
        const details = workoutPayload.details ?? ""
        const workoutTypeId = workoutPayload.type!.id
        const workout = { ...workoutPayload, joints, equipments, workoutTypeId, workoutChildren, videoDuration, duration, downloadUrl, details, relatedWorkouts: relatedWorkouts };
        var { type, id, scheduled, userWorkout, isCustom, workoutProgression, childCount, grandChildCount, ...payload } = workout;
        console.log(payload);
        if (p.id) {
            await putApiWorkoutWorkoutid(+p.id, payload);
        } else {
            await postApiWorkout(payload);
        }
        history(route(urls.routinesList));
    }
    return <Container>
        <Paper className={classes.root}>
            <Formik<WorkoutWithInfo>
                initialValues={workout}
                onSubmit={onSubmit}
                validationSchema={validateForm}
            >
                {({ values, handleChange, setValues, errors, touched }) => {
                    const handleCapture = async (e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.files) {
                            const file = e.target.files[0];
                            const result = await putApiWorkoutWorkoutidPicture(values.id, file);
                            setValues({ ...values, picture: result.picture });
                            setImgUrl(result.picture);
                        }
                    }
                    const handleAsyncChange = (key: keyof WorkoutPayload, value: number | number[]) => {
                        setValues({ ...values, [key]: value });
                    }
                    const forField = <K extends keyof WorkoutPayload>(key: K) => ({
                        handleAsyncChange,
                        defaultValue: values[key],
                        searchWord: key
                    })
                    const handleClick = (id: number) => {
                        let newArray = values.workoutChildren?.filter(e => e.id !== id);
                        setValues({ ...values, workoutChildren: newArray })
                    }
                    const handleEdit = (id: number) => {
                        if (edit === false) {
                            setEditable(id);
                            setEdit(true);
                        } else {
                            console.log(values.workoutChildren!.filter(e => e.id === id));
                            setEdit(false);
                        }
                    }
                    const handleRevert = (id: number) => {
                        const modifiedWorkoutChildren: any = values.workoutChildren?.map((workoutChildren, index) => {
                            if (id === index) {
                                return {
                                    ...workoutChildren, progression: {
                                        ...workoutChildren.workoutProgression,
                                    }
                                };
                            }
                            return { ...workoutChildren };
                        });
                        setValues({ ...values, workoutChildren: modifiedWorkoutChildren })
                    }
                    const resetProgressionId = (id: number) => {
                        const modifiedWorkoutChildren: any = values.workoutChildren?.map((workoutChildren, index) => {
                            if (id === index) {
                                return {
                                    ...workoutChildren, progression: {
                                        ...workoutChildren.progression,
                                        id: null,
                                    }
                                };
                            }
                            return { ...workoutChildren };
                        });
                        setValues({ ...values, workoutChildren: modifiedWorkoutChildren })
                    }
                    const handleReset = (e: any, id: number) => {
                        resetProgressionId(id);
                        handleChange(e);
                    }
                    const handleClickUp = (index: number) => {
                        const newArray: WorkoutBasic[] = [...values.workoutChildren ?? []];
                        if (index !== 0) {
                            newArray[index] = values.workoutChildren![index - 1]
                            newArray[index - 1] = values.workoutChildren![index]
                            setValues({ ...values, workoutChildren: newArray })
                        }
                    }
                    const handleClickDown = (index: number) => {
                        const newArray: WorkoutBasic[] = [...values.workoutChildren ?? []];
                        if (index !== values.workoutChildren!.length - 1) {
                            newArray[index] = values.workoutChildren![index + 1]
                            newArray[index + 1] = values.workoutChildren![index]
                            setValues({ ...values, workoutChildren: newArray })
                        }
                    }
                    return <Form>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4" gutterBottom>{p.id ? `Editing ${workout.name}` : "Add new Routine"}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={6}>
                                    <Grid item sm={4}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid item sm={4}>
                                        <Button
                                            onClick={e => {
                                                history(route(urls.routinesList));
                                            }}
                                            fullWidth
                                            variant="outlined"
                                            color="primary"
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <div style={{ float: "right", marginLeft: 20 }}>
                                            Hide: <Checkbox id={"hidden"} inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} value={values.hidden} checked={values.hidden} onChange={handleChange} />
                                        </div>
                                        <div style={{ float: "right" }}>
                                            Free: <Checkbox id={"free"} inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} value={values.free} checked={values.free} onChange={handleChange} />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{ margin: 0 }} container spacing={3}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Name"
                                        variant="outlined"
                                        value={values.name}
                                        id={"name"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Media Key"
                                        variant="outlined"
                                        value={values.mediaKey || ""}
                                        id={"mediaKey"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        required
                                        label="Download Url"
                                        variant="outlined"
                                        value={values.downloadUrl || ""}
                                        id={"downloadUrl"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        type="number"
                                        label="Duration"
                                        variant="outlined"
                                        id={"duration"}
                                        onChange={handleChange}
                                        error={(errors.duration && touched.duration) ? true : false}
                                        helperText={errors.duration ? errors.duration : null}
                                        value={values.duration ? values.duration : 0}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Frequency Min"
                                        variant="outlined"
                                        value={values.durationMin}
                                        id={"durationMin"}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="Frequency Max"
                                        variant="outlined"
                                        value={values.durationMax}
                                        id={"durationMax"}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4} >
                                    <AsyncAutocomplete
                                        label="Type"
                                        multiple={false}
                                        suggestions={getApiRoutine_typeList}
                                        defaultValue={values.type}
                                        searchWord="type"
                                        handleAsyncChange={(key, v, items) => {
                                            setValues({ ...values, type: items })
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        required
                                        label="Details"
                                        variant="outlined"
                                        value={values.details || ""}
                                        id={"details"}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AsyncAutocomplete
                                        label="Joints"
                                        multiple={true}
                                        suggestions={getApiJointList}
                                        {...forField("joints")}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AsyncAutocomplete
                                        label="Related routines"
                                        multiple={true}
                                        maxSelection={3}
                                        suggestions={() => getApiWorkoutList({ kind: [kind.routines] })}
                                        {...forField("relatedWorkouts")}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AsyncAutoCompleteTable
                                        label="Select Exercise"
                                        multiple={true}
                                        searchWord="name"
                                        value={[]}
                                        defaultValue={values.workoutChildren}
                                        suggestions={() => getApiWorkoutList({ kind: [kind.exercises] })}
                                        onChange={(newChildren) => {
                                            let newArray: any[] = values.workoutChildren!.map(e => e)
                                            newArray.push(newChildren)
                                            setValues({ ...values, workoutChildren: newArray })
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={4} >
                                        <div style={{ marginBottom: "1em" }}>
                                            <input
                                                accept="image/*"
                                                style={{ display: "none" }}
                                                id="raised-button-file"
                                                onChange={handleCapture}
                                                type="file"
                                            />
                                            <label htmlFor="raised-button-file">
                                                <Button variant="contained" component="span">
                                                    Upload Image <CloudUploadIcon style={{ paddingLeft: "0.2em" }} />
                                                </Button>
                                            </label>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid >
                                    <img alt="" src={`${imgUrl}?t=${Date.now()}`} width="400px" height="auto" />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {values.workoutChildren?.map((w, wIndex) =>
                                    < CardContent key={wIndex} style={{ paddingBottom: "2em", marginTop: "1.5em", borderStyle: "solid", borderColor: "#D3D3D3", borderWidth: "1px", borderRadius: "5px" }}>
                                        <Typography component="h2" style={{ fontSize: "1.2em" }}>
                                            {w.name}
                                            {edit && (editable === w.id) ? <>
                                                <IconButton aria-label="edit" style={{ float: 'right' }} size="small" onClick={() => handleEdit(w.id)}>
                                                    <SaveIcon />
                                                </IconButton>
                                                {w.workoutProgression?.id !== w.progression?.id && <IconButton
                                                    style={{ float: 'right' }}
                                                    title="Revert to original progression"
                                                    size="small"
                                                    onClick={() => handleRevert(wIndex)}
                                                >
                                                    <UndoIcon />
                                                </IconButton>}
                                            </> :
                                                <IconButton aria-label="edit" style={{ float: 'right' }} size="small" onClick={() => handleEdit(w.id)}>
                                                    <EditIcon />
                                                </IconButton>}
                                        </Typography>
                                        {/* {w.workoutProgression} */}
                                        <IconButton aria-label="move up" size="small" style={{ float: "left" }} onClick={() => handleClickUp(wIndex)}>
                                            <ArrowUpwordIcon />
                                        </IconButton>
                                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                                            <div style={{ maxWidth: '100%' }}>
                                                <Typography key={w.id} variant="body1" color="textSecondary" style={{ paddingLeft: "3em", flexDirection: 'row' }}>
                                                    {w.details}<br />
                                                    Joints: {w.joints?.map(j => <span key={j.id}>{j.name}</span>)}<br />
                                                    Equipments: {w.equipments?.map(q => <span key={q.id}>{q.name}</span>)}<br />
                                                </Typography>
                                            </div>
                                            <div style={{ flexGrow: 1 }}></div>
                                            <div style={{ marginRight: "10px", minWidth: "250px", color: w.workoutProgression?.id !== w.progression?.id ? "#5EA1DD" : "inherit" }}>
                                                {w.progression?.progression?.map((e) => <div key={e.order}>{e.order}  {e.reps ? `Reps: ${e.reps}` : undefined} {e.sets ? `Sets: ${e.sets}` : undefined}  {e.hold ? `Hold: ${e.hold}` : undefined} {e.cycles ? `Cycles: ${e.cycles}` : undefined}</div>)}
                                            </div>
                                        </div>

                                        {edit && (editable === w.id) &&
                                            <FieldArray
                                                name={`workoutChildren.${wIndex}.progression.progression`}
                                                render={arrayHelpers => (
                                                    <Grid container spacing={4} >
                                                        {w.progression?.progression && w.progression?.progression.length > 0 ? (
                                                            w.progression?.progression!.map((e, index: number) => (
                                                                <Grid item xs={12} key={index} style={{ marginTop: index === 0 ? "10px" : "none" }} >
                                                                    Progression {e.order = index + 1}:
                                                                    <TextField label="Reps" type="string" name={`workoutChildren.${wIndex}.progression.progression.${index}.reps`} value={e.reps ?? undefined} onChange={(e: any) => handleReset(e, wIndex)} variant="outlined" style={{ marginLeft: '1em', marginRight: '1em', width: '5.5em' }} />
                                                                    <TextField label="Sets" type="number" name={`workoutChildren.${wIndex}.progression.progression.${index}.sets`} value={e.sets ?? undefined} onChange={(e: any) => handleReset(e, wIndex)} variant="outlined" style={{ marginLeft: '1em', marginRight: '1em', width: '5.5em' }} />
                                                                    <TextField label="Hold" type="number" name={`workoutChildren.${wIndex}.progression.progression.${index}.hold`} value={e.hold ?? undefined} onChange={(e: any) => handleReset(e, wIndex)} variant="outlined" style={{ marginLeft: '1em', marginRight: '1em', width: '5.5em' }} />
                                                                    <TextField label="Cycles" type="number" name={`workoutChildren.${wIndex}.progression.progression.${index}.cycles`} value={e.cycles ?? undefined} onChange={(e: any) => handleReset(e, wIndex)} variant="outlined" style={{ marginLeft: '1em', marginRight: '1em', width: '5.5em' }} />
                                                                    <IconButton aria-label="delete" onClick={() => {
                                                                        resetProgressionId(wIndex);
                                                                        arrayHelpers.remove(index)
                                                                    }
                                                                    }>
                                                                        <Icon>delete</Icon>
                                                                    </IconButton>
                                                                    {index + 1 === w.progression?.progression?.length && w.progression?.progression?.length !== 3 ? <IconButton aria-label="add" onClick={() => {
                                                                        resetProgressionId(wIndex);
                                                                        arrayHelpers.insert(index + 1, { reps: undefined, sets: undefined, hold: undefined, cycles: undefined })
                                                                    }
                                                                    }>
                                                                        <Icon>add</Icon>
                                                                    </IconButton> : <div></div>}
                                                                </Grid>
                                                            ))
                                                        ) : (
                                                            <Grid item xs={12}>
                                                                <Button color="primary" variant="contained" onClick={() => {
                                                                    resetProgressionId(wIndex);
                                                                    arrayHelpers.push({ reps: undefined, sets: undefined, hold: undefined, cycles: undefined })
                                                                }
                                                                }>
                                                                    Add a progression
                                                                </Button>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                )}
                                            />}
                                        <IconButton aria-label="delete" style={{ float: 'right' }} size="small" onClick={() => handleClick(w.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                        <IconButton aria-label="move down" size="small" style={{ float: "left" }} onClick={() => handleClickDown(wIndex)}>
                                            <ArrowDownwardIcon />
                                        </IconButton>
                                    </CardContent>
                                )}
                            </Grid>
                        </Grid>
                    </Form>
                }}
            </Formik>
        </Paper>
    </Container >
}
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import MaterialTable, { Options } from '@material-table/core';
import { Equipment } from '../models/Equipment';
import { getApiEquipmentList, deleteApiEquipmentEquipmentid } from '../services/equipment';
import { urls, route } from '../route';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import { loadTableData } from './loaders';
import { confirmAndDelete } from '../utils';
export const EquipmentList = () => {
    const history = useNavigate();
    const ref = useRef<any>();
    const [pagination,] = useState<Options<Equipment>>({
        actionsColumnIndex: -1,
        selection: true,
        maxColumnSort: 0,
        pageSize: 10,
    });
    return <div>
        <MaterialTable<Equipment>
            title="Equipment"
            options={pagination}
            tableRef={ref}
            columns={[
                { title: "Id", field: "id", cellStyle: { width: "5%" } },
                { title: "Name", field: "name", width: "90%" },
                {
                    title: 'Tools',
                    render: (e) => (<>
                        <IconButton onClick={() => history(`/app/equipment/edit/${e.id}`)} style={{ float: "right" }}><Icon>edit</Icon></IconButton>
                        <IconButton onClick={confirmAndDelete(e, deleteApiEquipmentEquipmentid, ref)} style={{ float: "right" }}><Icon>delete</Icon></IconButton>
                    </>)
                }
            ]}
            data={loadTableData(getApiEquipmentList)}
            actions={[{
                icon: "delete",
                tooltip: "Delete",
                onClick: async (event, data) => {
                    const confirmation = window.confirm("Are you sure you want to delete the selected items?")

                    if (confirmation && Array.isArray(data)) {
                        for (const i of data) {
                            await deleteApiEquipmentEquipmentid(i.id);
                        }
                        if (ref.current) {
                            ref.current.onQueryChange();
                        }
                    }
                }
            }, {
                icon: "add",
                tooltip: "Add New",
                isFreeAction: true,
                onClick: () => history(route(urls.equipmentAdd)),
            }]}
        />

    </div>
};